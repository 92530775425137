.banner-cta {
  @apply mx-auto my-8 flex flex-col items-start justify-center gap-[10px] rounded-2xl bg-white p-5 shadow-md tablet:my-10 tablet:flex-row tablet:items-center tablet:justify-normal tablet:gap-5 tablet:p-6 desktop:max-w-4xl;
}

.banner-cta-logo-container {
  @apply flex items-center gap-3 self-stretch tablet:gap-4 tablet:self-auto;
}

.banner-cta-logo {
  @apply flex h-[50px] w-[50px] flex-shrink-0 items-center justify-center rounded-[10px] tablet:h-20 tablet:w-20;
}

.banner-cta-title-mobile {
  @apply whitespace-nowrap text-xl font-medium leading-6 tablet:hidden;
}

.banner-cta-content-container {
  @apply flex w-full flex-1 flex-col items-start gap-[10px] tablet:w-auto tablet:flex-row tablet:items-center tablet:gap-4;
}

.banner-cta-content {
  @apply flex flex-col gap-1 self-stretch tablet:self-auto;
}

.banner-cta-title-desktop {
  @apply hidden text-xl font-medium leading-6 tablet:block;
}

.banner-cta-button-container {
  @apply flex w-full justify-center self-stretch tablet:w-auto tablet:self-auto;
}

.banner-cta-button {
  @apply flex h-10 w-full min-w-[140px] items-center justify-center gap-2.5 whitespace-nowrap rounded-lg px-4 py-3 text-center font-medium leading-6 text-white tablet:w-auto;
}
