.social-content-carousel .swiper-slide-active {
  @apply !size-[220px] tablet:!size-[260px] desktop:!size-80;
}

.social-content-carousel .swiper-wrapper {
  @apply items-center;
}

.social-content-carousel :is(.video-button-prev, .video-button-next) {
  @apply h-[50px] w-[50px] rounded-full border-2 border-teal transition-colors duration-200 hover:border-black focus:border-[#00C9A7] focus:bg-[#00C9A7] focus:outline-none active:border-[#99E4D7] active:bg-[#99E4D7];
}

.social-content-carousel .embedded-instagram-post blockquote,
.social-content-carousel
  .embedded-post
  .instagram-media:not(.instagram-media-rendered) {
  @apply opacity-0;
}

.social-content-carousel .embedded-post .instagram-media {
  @apply transition delay-75 duration-75;
}

.social-content-carousel
  .embedded-post
  .instagram-media.instagram-media-rendered {
  @apply opacity-100;
}

.gradient {
  animation-name: gradient-animation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: #f4f4f4;
  background: linear-gradient(to right, #f5f5f5 20%, #e5e5e5 50%, #f5f5f5 80%);
  background-size: 1000px 320px;
}

@keyframes gradient-animation {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}
