@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-Thin-Web.woff');
    src: url('./GL-Graphik-Thin-Web.woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-ThinItalic-Web.woff');
    src: url('./GL-Graphik-ThinItalic-Web.woff2');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-Extralight-Web.woff');
    src: url('./GL-Graphik-Extralight-Web.woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-ExtralightItalic-Web.woff');
    src: url('./GL-Graphik-ExtralightItalic-Web.woff2');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-Light-Web.woff');
    src: url('./GL-Graphik-Light-Web.woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-LightItalic-Web.woff');
    src: url('./GL-Graphik-LightItalic-Web.woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-Regular-Web.woff');
    src: url('./GL-Graphik-Regular-Web.woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-RegularItalic-Web.woff');
    src: url('./GL-Graphik-RegularItalic-Web.woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-Medium-Web.woff');
    src: url('./GL-Graphik-Medium-Web.woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-MediumItalic-Web.woff');
    src: url('./GL-Graphik-MediumItalic-Web.woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-Semibold-Web.woff');
    src: url('./GL-Graphik-Semibold-Web.woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-SemiboldItalic-Web.woff');
    src: url('./GL-Graphik-SemiboldItalic-Web.woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-Bold-Web.woff');
    src: url('./GL-Graphik-Bold-Web.woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-BoldItalic-Web.woff');
    src: url('./GL-Graphik-BoldItalic-Web.woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-Black-Web.woff');
    src: url('./GL-Graphik-Black-Web.woff2');
    font-weight: 800;
    font-display: auto;
    font-style: normal;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-BlackItalic-Web.woff');
    src: url('./GL-Graphik-BlackItalic-Web.woff2');
    font-weight: 800;
    font-style: italic;
}


@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-Super-Web.woff');
    src: url('./GL-Graphik-Super-Web.woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'GL Graphik Web';
    src: url('./GL-Graphik-SuperItalic-Web.woff');
    src: url('./GL-Graphik-SuperItalic-Web.woff2');
    font-weight: 900;
    font-style: italic;
}