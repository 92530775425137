@import "tailwindcss/components";
@import "./components.css";
@tailwind base;
@tailwind utilities;

.column {
    @apply box-border flex shrink-0 grow-0 ;
}

.block-container {
    @apply w-full flex justify-center scroll-mt-16 box-border; 
}

.hero-container {
    @apply w-full justify-center scroll-mt-16 box-border;
}

.block-container > * {
    @apply max-w-screen-max;
  }  

.layout {
    @apply flex flex-wrap flex-1 justify-center items-center box-border w-full px-5 tablet:px-10 desktop:px-14 py-10;
}

.box-column {
    @apply shrink-0 grow-0 box-border;
}

.heading-2 {
    @apply font-medium desktop:text-heading2 desktop:leading-12 desktop:tracking-[-0.0525rem] leading-8 text-heading2-mobile tablet:text-[32px] tablet:leading-[36px];
}

.heading-3 {
    @apply font-medium text-xl tablet:text-2xl tablet:tracking-[-0.03rem];
}

.body-medium {
    @apply text-xl font-normal leading-8;
}

.body-small {
    @apply text-base font-normal;
}

.body-extra-small {
    @apply text-xs leading-5 desktop:text-sm;
}

.primary-button {
    @apply grow desktop:grow-0 shrink basis-0 h-10 px-4 py-2 rounded-lg justify-center items-center gap-2.5 flex;
}

